import React from "react"
import BumbaLogo from "../images/BumbaLogo.png"

const Footer = () => (
  <div className="footer">
    <a href="https://www.bumbaeditorial.com">
    <div className="footer-image-wrapper">
      <img className="logo-image" src={BumbaLogo} alt=""/>
    </div>
    </a>
  </div>
)

export default Footer
